<template>
        <button type="button" class="button">
            <slot></slot>
        </button>
</template>

<script>
export default {

}
</script>

<style scoped>
    .button{
        position: relative;
        padding: 50px 75px;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 28px;
        cursor: pointer;
    }
    .button::before{
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        border: 1px solid #000;
        border-radius: 50%;
        top: 0;
        left: 0;
        transform: rotate(3deg);
        transition: all linear .2s;
    }
    .button::after{
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        border: 1px solid #000;
        border-radius: 50%;
        top: 0;
        left: 0;
        transform-origin: top left;
        transform: rotate(-3deg);
        transition: all linear .2s;
    }
    .button:hover::after,
    .button:hover::before{
        transform: rotate(0deg);
    }
    @media (max-width: 600px) {
        .button{
            font-size: 20px;
            padding: 30px;
        }
    }
</style>