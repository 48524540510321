<template>
    <div class="popup">
        <div class="popup__inner">
            <div class="popup__form form">
                <button class="popup__close" @click="close"></button>
                <h1 class="form__heading heading">Не нашли<br> подходящий букет?</h1>
                <h3 class="form__subheading">Перезвоним в течение 10 минут!</h3>
                <form 
                    class="form__enter enter-form" 
                    @submit.prevent="getConsultation">
                    <input 
                        placeholder="Введите телефон" 
                        class="form__input form__input_pass input" 
                        v-imask="phoneNumberMask"
                        maxlength="16" 
                        v-model="state.tel">
                    <div class="enter-form__btns">
                        <button 
                            class="enter-form__enter enter-form__btn btn"
                            type="submit"
                            >{{submitBtnText}}</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import useValidate from '@vuelidate/core'
import { required} from '@vuelidate/validators'
import { reactive } from 'vue'
import { IMaskDirective } from 'vue-imask';

export default {
    setup(){
        const state = reactive({
            tel: ''
        })
        const rules = {
            tel: {required}, 
        }
        const v$ = useValidate(rules, state)
        return {state, v$}
    },
    data() {
        return {
            isLoading: false,
            phoneNumberMask: {
                mask: '+{7}(000)000-00-00',
                lazy: true
            }
        }
    },
    methods: {
        close(){
            this.$emit('closeConsultation')
        },
        async getConsultation(){
            const isFormCorrect = await this.v$.$validate()
            if(!isFormCorrect){
                return alert('Ошибка в форме')
            }
            this.isLoading = true

            axios({ 
                method: 'GET',
                url: `https://api.telegram.org/bot6443703954:AAGDil0GIamTkVc3IZKaXF1s8ZyZSCRs7DE/sendMessage?chat_id=-1001592627959&text=Консультация ${this.state.tel}`,
            })  
            .then(()=>{
                alert('Отправлено! Наш оператов перезвонит Вам в ближайшее время')
                this.close()
            })
        },
    },
    computed: {
        submitBtnText(){
            return this.isLoading ? 'Загрузка...' : 'Позвоните мне'
        },
    },
    directives: {
        imask: IMaskDirective
    }
}
</script>

<style scoped>
    .popup__inner{
        position: fixed;
        display: flex;
        z-index: 1500;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 60px;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, .5);
    }
    .popup__close{
        position: absolute;
        top: 45px;
        right: 60px;
        height: 20px;
        width: 20px;
    }
    .popup__close::before{
        position: absolute;
        content: "";
        height: 2px;
        width: 25px;
        background-color: #707070;
        top: 12px;
        right: 0;
        transform: rotate(45deg);
    }
    .popup__close::after{
        position: absolute;
        content: "";
        height: 2px;
        width: 25px;
        background-color: #707070;
        top: 12px;
        right: 0;
        transform: rotate(-45deg);
    }
    .popup__form{
        position: relative;
        padding: 70px 160px;
        border-radius: 30px;
        width: 100%;
        max-width: 750px;
        background-image: url(../../images/popup-deco-top.png), url(../../images/popup-deco-bottom.png);
        background-color: rgba(255, 255, 255, .75) ;
        background-repeat: no-repeat no-repeat;
        backdrop-filter: blur(9px);
        background-position: top left, bottom right;
    }
    .form__heading{
        font-size: 32px;
        margin-bottom: 20px;
    }
    .form__subheading{
        font-family: 'Playfair Display', serif;
        font-size: 18px;
        margin-bottom: 40px;
        text-align: center;
    }
    .form__enter{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 290px;
    }
    .form__input{
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 10px;
        font-size: 14px;
    }
    .form__input_pass{
        margin-bottom: 20px;
    }
    .enter-form__btns{
        display: flex;
    }
    .enter-form__btn{
        width: 100%;
        padding: 15px 0;
    }
    .enter-form__enter{
        width: 100%;
        padding: 11px;
        font-size: 14px;
        border-radius: 10px;
    }
    .enter-form__checkbox{
        display: flex;
        justify-content: center;
    }
    .checkbox__checkbox{
        position: relative;
        display: inline-block;
        vertical-align: baseline;
        height: 20px;
        width: 20px;
        margin-right: 20px;
        margin-bottom: -2px;
        border: 1px solid #000;
        border-radius: 4px;
        cursor: pointer;
    }
    .checkbox__input{
        display: none;
    }
    .checkbox__label{
        cursor: pointer;
    }
    .checkbox_select{
        background-color: #000;
        transition: all linear .1s;
    }
    .checkbox_select::before{
        position: absolute;
        content: "";
        height: 12px;
        width: 2px;
        top: 3px;
        right: 6px;
        transform: rotate(35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox_select::after{
        position: absolute;
        content: "";
        height: 7px;
        width: 2px;
        bottom: 4px;
        left: 5px;
        transform: rotate(-35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .invalid{
        border-color: red;
    }
    .form__notify{
        text-align: center;
        margin-bottom: 25px;
        color: #707070;
    }
    @media (max-width: 600px) {
        .popup__form{
            background-size: 30% 30%, 30% 30%;
        }
        .popup__inner{
            padding: 0;
        }
        .popup__form{
            padding: 60px 15px 30px 15px;
        }
        .popup__close{
            top: 25px;
            right: 15px;
        }
        .form__input{
            margin-bottom: 15px;
        }
        .checkbox__label{
            font-size: 14px;
        }
    }
</style>