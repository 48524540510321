<template>
    <div class="main-banner">
        <div class="main-banner__inner">
            <div class="slide__wrapper">
                <!-- <div class="main-banner__text slide-text">
                    <div class="slide-text__left slide-text-left">
                        <span class="slide-text-left__discount">скидка</span>
                        <span class="slide-text-left__value">15%</span>
                        <p class="slide-text-left__text">до 23.11.23 включительно</p>
                    </div>

                    <div class="slide-text__right slide-text-right">
                        <span class="slide-text-right__heading">по промокоду</span>
                        <span class="slide-text-right__promo">”Мама”</span>
                    </div>
                </div> -->

                <!-- <img 
                    class="slide__img" 
                    src="../images/main-banners/banner-new.png"> -->
            </div>
            <swiper
                :slides-per-view="1"
                :space-between="0"
                :modules="modules"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                :autoHeight="true"
                class="slider slider-desc"
                :navigation="{
                    prevEl: prev,
                    nextEl: next,
                  }"
                :pagination="{clickable: false, pagination: true}"
                :style="{
                    '--swiper-pagination-color': `${paginationColor}`,
                    '--swiper-pagination-bullet-inactive-color': '#fff',
                    '--swiper-pagination-bullet-inactive-opacity': '1',
                    '--swiper-pagination-bullet-horizontal-gap': `${horizontalGap}px`,
                    '--swiper-pagination-bottom': `${paginationBottom}px`,
                    '--swiper-pagination-bullet-size': '12px'
                  }"
                >

                <!-- <swiper-slide class="slider__slide slide">
                    <div class="slide__wrapper">
                        <div class="slide__content">
                            <h2 class="slide__heading heading">Скидка - 10%</h2>
                            <p class="slide__text">за подписку на нашу группу вконтакте: <a href="https://vk.com/dff24" class="slide__link">@dff24ru</a></p>
                        </div>
                    </div>
                </swiper-slide> -->
                
                <!-- <swiper-slide class="slider__slide slide-alue slider__slide-alue slide">
                    <div class="slide-alue__inner">
                        <h1 class="slide-alue__heading">Алые Паруса</h1>
                        <p class="slide-alue__text">скидка на предзаказ букетов</p>
                        <p class="slide-alue__text slide-alue__text-offset slide-alue__text-desc">- 10% по промокоду "паруса"</p>
                        <p class="slide-alue__text slide-alue__text-offset slide-alue__text-mob">от 10 шт - 10% от 20 шт - 15%</p> 
                        <button @click="redirectToCatalog" type="button" class="slide-alue__btn">Выбрать букет</button>
                    </div>
                </swiper-slide> -->

                <!-- <swiper-slide class="slider__slide slider__slide-september slide-sept slide">
                    <div class="slide-sept__content">
                        <p class="slide-sept__text"><span class="slide-sept__promo">«СЕНТЯБРЬ»</span> промокод на скидку 10%</p>
                        <p class="slide-sept__text">при заказе от 3 500Р</p>
                    </div>
                </swiper-slide>  -->

                <swiper-slide class="slider__slide slider__slide-mother slide mother">
                    <div class="mother__inner">
                        <h1 class="mother__heading">ДЕНЬ МАТЕРИ</h1>
                        <p class="mother__text">принимаем предзаказы на букеты</p>
                        <button 
                            type="button" 
                            @click="redirectToMother"
                            class="mother__btn">Выбрать букет</button>
                    </div>
                </swiper-slide>

                <swiper-slide class="slider__slide slider__slide-free slide">
                    <div class="slide__free-inner">
                        <p class="slide__top-text">duty free flowers</p>
                        <p class="slide__middle-text">Доставка<br>бесплатная</p>
                        <p class="slide__bottom-text">в пределах города<br class="slide__wrap"> при заказе от 5000 рублей</p>
                    </div>
                </swiper-slide>

                <swiper-slide class="slider__slide slider__slide-2 slide-2">
                    <div class="slide-2__inner container">
                        <div class="slide-2__wrapper">
                            <h1 class="slide-2__heading heading">свадебная</h1>
                            <h2 class="slide-2__subheading">флористика</h2>
                            <p class="slide-2__text slide-2__text_desc">Свадьба - волшебство момента, искренность чувств и неподражаемая красота! Цветы – неотъемлемая часть свадьбы, они придают особое очарование и подчеркивают уникальность момента.</p>

                            <p class="slide-2__text">
                            Свадебная флористика – это искусство создания неповторимых композиций из живых цветов, которые становятся символом вашей любви и страсти. Цветы на свадьбе могут быть использованы в букете невесты, бутоньерках для жениха и гостей, а также в оформлении свадебного зала и столов.</p>

                            <span class="slide-2__discount">скидка 20% </span>
                        </div>
                    </div>
                </swiper-slide>

                <!-- <swiper-slide class="slider__slide slider__slide-f slide">
                    <img 
                        class="slide__img" 
                        src="../images/main-banners/8-mar.png">
                        <div class="slide__block">
                            <h1 class="slide__heading">Duty Free<br>Flowers</h1>
                            <p class="slide__text slide__text-discount">скидка 10% по промокоду «ВЕСНА»</p>
                            <p class="slide__text">на первый заказ</p>
                            <button 
                                class="slide__btn"
                                type="button"
                                @click="redirectToCatalog">Выбрать букет</button>
                        </div>
                </swiper-slide> -->
                

                <!-- <swiper-slide class="slider__slide slider__slide-1 slide-1">
                    <div class="slide-1__inner">
                        <h1 class="slide-1__heading heading">
                            доставка в пределах города <span class="slide-1__heading-marked">бесплатная</span></h1>
                            <p class="slide-1__text">при покупке от 5 000 рублей</p>
                    </div>
                </swiper-slide> -->

                

                <swiper-slide class="slide corp">
                    <div class="corp__inner">
                        <h1 class="corp__heading">КОРПОРАТИВНЫЕ <br>ЗАКАЗЫ</h1>
                        <p class="corp__text">Цветочное оформление офисов, мероприятий, создание цветочных композиций в фирменной стилистике компании</p>
                        <button 
                            class="corp__btn"
                            @click="openPopup">ОФОРМИТЬ ЗАКАЗ</button>
                    </div>
                </swiper-slide>
            </swiper>

            <!-- Моб баннер -->
            <!-- <div class="main-banner__mob">
                <swiper
                    :slides-per-view="1"
                    :space-between="0"
                    :modules="modules"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    class="slider"
                    :navigation="{
                        prevEl: prev,
                        nextEl: next,
                    }"
                    :autoHeight = "true"  
                    :pagination="{clickable: false, pagination: true}"
                    :style="{
                        '--swiper-pagination-color': `${paginationColor}`,
                        '--swiper-pagination-bullet-inactive-color': '#fff',
                        '--swiper-pagination-bullet-inactive-opacity': '1',
                        '--swiper-pagination-bullet-horizontal-gap': `${horizontalGap}px`,
                        '--swiper-pagination-bottom': `${paginationBottom}px`,
                        '--swiper-pagination-bullet-size': '12px'
                    }"
                    >
                     
                    <swiper-slide class="slider__slide slide">
                        <div class="slide__wrapper">
                            <div class="slide__content">
                                <h2 class="slide__heading heading">Скидка - 10%</h2>
                                <p class="slide__text">за подписку на нашу группу вконтакте: <a href="https://vk.com/dff24" class="slide__link">@dff24ru</a></p>
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="slider__slide slider__slide-1 slide-1">
                        <div class="slide-1__inner">
                            <h1 class="slide-1__heading heading">
                                доставка<br> в пределах города <span class="slide-1__heading-marked">бесплатная</span></h1>
                                <p class="slide-1__text">при покупке от 5 000 рублей</p>
                        </div>
                    </swiper-slide>

                    

                    <swiper-slide class="slider__slide slider__slide-2 slide-2">
                        <div class="slide-2__inner container">
                            <div class="slide-2__wrapper">
                                <h1 class="slide-2__heading heading">свадебная</h1>
                                <h2 class="slide-2__subheading">флористика</h2>
                                <p class="slide-2__text">Флористика для свадеб ― это искусство создания атмосферы любви и нежности. Место регистрации, банкетный зал или ресторан, свадебный кортеж ― живые цветы будут отлично смотреться во всех этих местах. </p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div> -->

            <!-- <div class="main-banner__footer">
                <div class="main-banner__controls">
                    <div ref="prev" class="swiper-button-prev"></div>
                    <div ref="next" class="swiper-button-next"></div>
                </div>
                <div class="main-banner__social"> 
                    <a href="https://vk.com/dff24" class="main-banner__link main-banner__link_vk"></a>
                    <a href="https://t.me/+79219548941" class="main-banner__link main-banner__link_tg"></a>
                    <a href="https://api.whatsapp.com/send?phone=79219548941" class="main-banner__link main-banner__link_wa"></a>
                    <a href="https://www.instagram.com/dff24ru/" class="main-banner__link main-banner__link_inst"></a>
                </div>
            </div> -->
        </div>
        <CorpPopup 
            v-if="isPopupOpened"
            @closePopup="closePopup"/>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref } from 'vue';
import CorpPopup from '@/components/popups/Corp-popup'

import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
    data() {
        return {
            paginationColor: '#000000',
            horizontalGap: 10,
            paginationBottom: 30,
            isPopupOpened: false
        }
    },
    setup() {
        const onSwiper = (swiper) => {
            
        };
        const onSlideChange = () => {

        };
        const prev = ref(null);
        const next = ref(null);
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination],
            prev,
            next,
        };
    },
    components: {
        Swiper,
        SwiperSlide,
        CorpPopup
    },
    computed: {
        ...mapGetters([
            'BANNERS'
        ])
    },
    methods: {
        ...mapActions([
            'RESET_SEARCH_VALUE',
            'RESET_PAGE_NUMBER',
            'SELECT_CATEGORY',
            'GET_PRODUCTS'
        ]),
        // selectAlue(){
        //     this.RESET_SEARCH_VALUE(); 
        //     this.RESET_PAGE_NUMBER()
        //     this.SELECT_CATEGORY('Все');
        //     this.GET_PRODUCTS();
        //     this.$router.push({name: 'catalog', query: {'category': 'Акции', 'page': 1}})
        // },
        redirectToCatalog(){
            this.RESET_SEARCH_VALUE(); 
            this.RESET_PAGE_NUMBER()
            this.SELECT_CATEGORY('Все');
            this.GET_PRODUCTS();
            this.$emit('scrollToBlock')
            // this.$router.push({name: 'catalog', query: {'category': 'Все', 'page': 1}})
        },
        redirectToMother(){
            this.RESET_SEARCH_VALUE(); 
            this.RESET_PAGE_NUMBER()
            this.SELECT_CATEGORY('День Матери');
            this.GET_PRODUCTS();
            this.$emit('scrollToBlock')
            this.$router.push({query: {'category': 'День Матери', 'page': 1}})
        },
        openPopup(){
            this.isPopupOpened = true
        },
        closePopup(){
            this.isPopupOpened = false
        }
    },
    mounted() {
        // this.GET_BANNERS()
        if(window.screen.width <= 600){
            this.paginationColor = '#C8A2E5'
            this.horizontalGap = 15
            this.paginationBottom = 20
        }
    },
}
</script>

<style scoped>
    .main-banner__text{
        display: none;
    }
    .main-banner__inner{
        /* position: relative; */
        /* background-color: #99949c; */
    }
    .slide__wrapper{
        display: flex;
        background-color: #99949c;
    }
    .main-banner__footer{
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 60px;
        left: 0;
        right: 0;
        max-width: 1270px;
        margin: 0 auto;
        padding: 0 15px;
        z-index: 10;
    }
    /* .slider__item{
        display: block;
        width: 100%;
        height: 100vh;
    } */
    .slide__img{
        width: 100%;
    }
    .slider__slide{
        margin-top: 100px;
        position: relative;
        cursor: grab;
    }
    .slider__slide-f{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 800px;
        background: url(../images/main-banners/spring.webp) no-repeat;
        background-size: cover;
    } 
    .slider__slide-alue{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 800px;
        background: url(../images/main-banners/alue.png) no-repeat;
        background-size: cover;
    }
    .slide-alue__inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -100px;
        padding: 75px;
        backdrop-filter: blur(5px);
        background: rgba(13, 13, 13, 0.1);
        color: #fff;
    }
    .slide-alue__heading{
        text-align: center;
        font-family: 'Lighthaus';
        font-size: 130px;
        font-weight: 400;
        line-height: 96%;
        letter-spacing: 0.06rem;
        margin-bottom: 25px;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
    .slide-alue__text{
        font-size: 37px;
        font-family: "Tenor Sans", sans-serif;
        max-width: 605px;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        line-height: 118%;
        letter-spacing: 0.06em;
        text-align: center;
    }
    .slide-alue__text-offset{
        margin-bottom: 40px;
    }
    .slide-alue__btn{
        font-size: 35px;
        font-family: "Tenor Sans", sans-serif;
        text-transform: uppercase;
        padding: 15px 25px;
        border-radius: 9px;
        color: #B91004;
        background-color: #fff;
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14);
    }
    .slide-alue__text-mob{
        display: none;
    }
    .slider__slide-september{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        min-height: 800px;
        background: #eee;
        background: url(../images/main-banners/sept-ban.png) no-repeat;
        /* background-size: 100%; */
        background-size: cover;
    }
    .slider__slide-september::after{
        position: absolute;
        content: "";
        height: 250px;
        width: 100%;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.71) 28.49%, rgba(52, 52, 52, 0) 100%);
        bottom: 20px;
        left: 0;
    }
    .slide-sept__content{
        font-family: 'Mont';
        position: relative;
        font-size: 30px;
        margin-bottom: 180px;
        color: #fff;
        z-index: 1000;
    }
    .slide-sept__promo{
        font-weight: 600;
    }
    .slide-sept__text{
        text-align: center;
    }
    .slider__slide-free{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 800px;
        background: url(../images/main-banners/free-ban.webp) no-repeat;
        background-size: cover;
    }
    .slide__free-inner{
        min-height: 800px;
        padding-top: 80px;
        padding-bottom: 180px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .slider__slide-mother{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 800px;
        background: url(../images/main-banners/mother-ban.png) no-repeat;
        background-size: cover;
        padding-right: 15px;
        padding-left: 15px;
    }
    .mother__inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 95px 60px;
        border-radius: 35px;
        backdrop-filter: blur(26.5px);
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
        font-family: "Tenor Sans", sans-serif;
        margin-bottom: 80px;
    }
    .mother__heading{
        text-align: center;
        line-height: 100%;
        font-size: 129px;
        font-weight: 400;
        margin-bottom: 40px;
    }
    .mother__text{
        text-align: center;
        font-size: 53px;
        margin-bottom: 40px;
    }
    .mother__btn{
        color: #972C33;
        background-color: #fff;
        text-transform: uppercase;
        font-family: "Tenor Sans", sans-serif;
        transition: all linear .2s;
        border-radius: 9px;
        font-size: 35px;
        font-weight: 700;
        padding: 15px 25px;
    }
    .mother__btn:hover{
        background-color: #972C33;
        color: #fff;
    }
    .slide__block{
        /* position: absolute; */
        width: 100%;
        max-width: 1095px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        z-index: 1000;
        top: 0;
        left: 0;
        backdrop-filter: blur(10px);
        background: rgba(13, 13, 13, 0.1);
        padding-top: 40px;
        padding-bottom: 60px;
    }
    .slide__top-text{
        font-family: 'Lighthaus';
        font-size: 43px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        line-height: 100%;
    }
    .slide__middle-text{
        font-family: 'Lighthaus';
        font-size: 137px;
        line-height: 100%;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
    }
    .slide__bottom-text{
        font-family: 'Lighthaus';
        font-size: 34px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        line-height: 100%;
    }
    .slide__text{
        font-size: 37px;
        font-family: "Tenor Sans", sans-serif;
        font-weight: 400;
        margin-bottom: 50px;
    }
    .slide__text-discount{
        margin-bottom: 10px;
    }
    .slider__slide-1{
        background: url(../images/main-banners/banner-2.webp) no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .slide-1__heading{
        font-size: 60px;
        font-weight: 400;
        max-width: 700px;
        margin-bottom: 25px;
    }
    .slide-1__heading-marked{
        color: #fff;
        background-color: #C8A2E5;
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 110%;
    }
    .slide-1__text{
        text-align: center;
        color: #666666;
        font-size: 24px;
    }
    .slider__slide-2{
        height: 100%;
        background: url(../images/main-banners/banner-wed.webp) no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
    }
    .slide-2__inner{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding-top: 90px;
        padding-bottom: 90px;
    }
    .slide-2__wrapper{
        display: flex;
        flex-direction: column;
        max-width: 595px;
    }
    .slide-2__heading{
        text-align: left;
        font-size: 62px;
    }
    .slide-2__subheading{
        font-family: 'MADE TheArtist Script';
        color: #A875D2;
        font-weight: 400;
        font-size: 100px;
        line-height: 50%;
        margin-left: 50px;
        margin-bottom: 50px;
    }
    .slide-2__text{
        line-height: 153%;
    }
    .slide-2__discount{
        font-size: 25px;
        margin-top: 30px;
        align-self: flex-end;
        font-family: 'Playfair Display', serif;
        color: rgba(168, 117, 210, .5);
    }
    .main-banner__controls{
        display: flex;
    }
    .swiper-button-prev,
    .swiper-button-next{
        position: relative;
        color: #474747;
        height: 70px;
        width: 70px;
        border-radius: 50%;
        background-color: rgba(212, 216, 218, .35);
        transition: all linear .2s;
    }
    .swiper-button-prev{
        margin-right: 60px;
    }
    .swiper-button-prev:hover,
    .swiper-button-next:hover{
        transform: scale(.95);
    }
    .main-banner__link{
        display: inline-block;
        height: 45px;
        width: 45px;
        background-color: rgba(0, 0, 0, .2);
        border-radius: 50%;
        margin-left: 25px;
        transition: all linear .2s;
        background-repeat: no-repeat;
        background-position: center;
    }
    .main-banner__link:hover{
        opacity: .5;
    }
    .main-banner__link_vk{
        background-image: url(../images/icons/vk-white-ico.svg); 
    }
    .main-banner__link_tg{
        background-image: url(../images/icons/tg-white-ico.svg); 
    }
    .main-banner__link_wa{
        background-image: url(../images/icons/wa-white-ico.svg); 
    }
    .main-banner__link_inst{
        background-image: url(../images/icons/insta-white-ico.svg); 
    }
    .main-banner__mob{
        display: none;
    }
    /* .slide__wrapper{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url(../images/main-banners/main-slide.png) no-repeat;
        background-size: cover;
        color: #fff;
    } */
    .slide__content{
        max-width: 480px;
        margin-left: 480px;
        margin-top: -120px;
    }
    .slide__heading{
        font-family: 'Lighthaus', sans-serif;
        text-align: center;
        line-height: 96%;
        font-size: 130px;
        font-weight: 400;
        margin-bottom: 30px;
    }
    
    .slide__link{
        color: #fff;
        text-decoration: none;
    }
    .slide__btn{
        color: #D13764;
        background-color: #fff;
        font-family: "Tenor Sans", sans-serif;
        font-size: 35px;
        text-transform: uppercase;
        padding: 15px 30px;
        border-radius: 9px;
    }
    /* @media (max-width: 1650px){
        .slide__heading{
            font-size: 200px;
            margin-bottom: 5px;
        }
        .slide__text{
            font-size: 45px;
            margin-bottom: 35px;
        }
    } */
    .corp__inner{
        background: url(../images/main-banners/corp-ban.png) no-repeat;
        background-size: cover;
        min-height: 800px;
        padding-top: 108px;
        padding-left: 100px;
    }
    .corp__heading{
        font-size: 95px;
        font-weight: 400;
        font-family: "Oranienbaum", serif;
        margin-top: 80px;
        line-height: 100%;
        letter-spacing: 0.06em;
        color: #392b28;
        margin-bottom: 20px;
    }
    .corp__text{
        font-size: 32px;
        max-width: 915px;
        margin-bottom: 50px;
    }
    .corp__btn{
        padding: 40px 55px;
        border-radius: 84px;
        background: radial-gradient(86.78% 86.78% at 50% 50%, rgb(241, 135, 176) 0%, rgb(253, 133, 181) 58.49999785423279%, rgb(248, 168, 195) 100%);
        color: #fff;
        font-size: 28px;
        transition: all linear .2s;
    }
    .corp__btn:hover{
        opacity: .7;
    }
    .slide__wrap{
        display: none;
    }
    @media (max-width: 1240px) {
        .slider__item{
            height: 100%;
        }
        .slide-2__wrapper{
            background-color: #E8DAD9;
            box-shadow: 0 0 15px 15px #E8DAD9;
        }
        .corp__inner{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    @media (max-width: 992px) {
        .corp__inner{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .corp__heading{
            text-align: center;
        }
        .corp__text{
            text-align: center;
        }
        .slide__middle-text{
            font-size: 80px;
        }
    }
    @media (max-width: 768px) {
        .slide__heading{
            font-size: 51px;
        }
        .slide__text{
            font-size: 20px;
            margin-bottom: 35px;
        }
        .slide__btn{
            margin-top: auto;
            margin-bottom: 80px;
        }
        .corp__heading{
            font-size: 42px;
            margin-bottom: 10px;
        }
        .corp__text{
            font-size: 18px;
        }
        .corp__btn{
            font-size: 14px;
            padding: 20px 30px;
        }
        .mother__heading{
            font-size: 60px;
        }
        .mother__text{
            font-size: 35px;
        }
        .mother__btn{
            font-size: 32px;
        }
    }
    @media (max-width: 600px) {
        .corp__inner{
            background: url(../images/main-banners/corp-ban-mob.png) no-repeat;
            background-size: 100% 100%;
            min-height: auto;
            height: 100vh;
            max-height: 666px;
            padding-top: 90px;
        }
        .corp__heading{
            margin-top: 30px;
            text-shadow: 0px 4px 4px rgba(161, 140, 133, 0.42);
        }
        .corp__text{
            text-shadow: 0 4px 4px rgba(161, 140, 133, 0.42);
        }
        .corp__btn{
            margin-top: auto;
            margin-bottom: 60px;
        }
        .slider__slide-f{
            position: relative;
            padding-top: 90px;
            min-height: 666px;
        }
        .slider__slide-f::before{
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 90px;
            width: 100%;
            background-color: #fff;
        }
        .slider__slide{
            margin-top: 0;
            height: 100vh;
            max-height: 575px;
            background: url(../images/main-banners/spring-mob.png) no-repeat;
            background-size: 100% 100%;
        }

        .slider__slide-alue{
            position: relative;
            min-height: 666px;
            padding-top: 90px;
            background: url(../images/main-banners/alue-mob.png) no-repeat;
            background-size: 100% 100%;
            padding-right: 15px;
            padding-left: 15px;
        }
        .slider__slide-alue::before{
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 90px;
            width: 100%;
            background-color: #fff;
        }
        .slider__slide-september{
            margin-top: 90px;
            background: url(../images/main-banners/sept-ban-mob.png) no-repeat;
            background-size: 100%;
            min-height: 666px;
        }
        .slide-sept__content{
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 150px;
            font-size: 14px;
        }
        .slide-sept__promo{
            font-size: 18px;
        }
        .slide-alue__inner{
            padding: 30px 20px;
            margin-top: 0;
            /* display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: -100px;
            padding: 75px;
            backdrop-filter: blur(5px);
            background: rgba(13, 13, 13, 0.1);
            color: #fff; */
        }
        .slide-alue__heading{
            font-size: 54px;
            margin-bottom: 15px;
            line-height: 105%;
        }
        .slide-alue__text{
            font-size: 19px;
            letter-spacing: 0;
        }
        /* .slide-alue__text-desc{
            display: none;
        } */
        .slide-alue__text-mob{
            display: block;
            max-width: 150px;
        }
        .slide-alue__text-offset{
            margin-bottom: 30px;
        }
        .slide-alue__btn{
            font-size: 20px;
            padding: 10px 15px;
        }

        .slider__slide-free{
            position: relative;
            background: url(../images/main-banners/free-ban-mob.png) no-repeat;
            background-size: 100% 100%;
            min-height: 666px;
            padding-top: 90px;
        }
        .slider__slide-free::before{
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 90px;
            width: 100%;
            background-color: #fff;
        }
        .slide__free-inner{
            padding-top: 60px;
            padding-bottom: 160px;
            min-height: 666px;
        }
        .slide__top-text{
            font-size: 24px;
        }
        .slide__middle-text{
            font-size: 45px;
        }
        .slide__bottom-text{
            font-size: 18px;
            max-width: 300px;
        }
        .slide__wrap{
            display: block;
        }
        .slide__block{
            margin-left: 15px;
            margin-right: 15px;
            padding-top: 50px;
            padding-bottom: 60px;
        }
        .slide__btn{
            font-size: 20px;
            font-weight: 700;
            padding: 10px 20px;
            margin-bottom: 0;
        }
        .slide__heading{
            margin-bottom: 20px;
        }
        .slide__text{
            font-size: 24px;
            text-align: center;
            max-width: 360px;
            margin-bottom: 25px;
        }
        .slide__text-discount{
            margin-bottom: 5px;
        }
        .slide__img{
            display: none;
        }
        /* .slide__wrapper{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            height: 70vh;
            background: url(../images/main-banners/mob-banner.png) no-repeat;
            background-size: cover;
        } */
        .slide__content{
            margin-bottom: 150px;
            padding-left: 15px;
            margin-top: 0;
            margin-left: 0;
        }
        .main-banner__footer{
            display: none;
        }
        /* .slider-desc{
            display: none;
        }
        .main-banner__mob{
            display: block;
        } */
        
        
        .slider__slide-1{
            background-image: url(../images/main-banners/mob-banner-3.png);
        }
        .slide-1__heading{
            font-size: 40px;
            max-width: 350px;
            margin-bottom: 10px;
        }
        .slide-1__text{
            font-size: 18px;
        }
        .slider__slide-2{
            position: relative;
            /* background-image: url(../images/main-banners/banner-wed-mob.png); */
            max-height: 666px;
            padding-top: 90px;
            background: #EBE6E5;
        }
        .slider__slide-2::before{
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 90px;
            width: 100%;
            background-color: #fff;
        }
        .slide-2__heading{
            font-size: 40px;
        }
        .slide-2__subheading{
            font-size: 71px;
            margin-left: 40px;
            margin-bottom: 25px;
        }
        .slide-2__inner{
            height: 100%;
            /* height: 1000px; */
            padding: 0 15px;
            flex-direction: column;
            justify-content: initial;
            background: url(../images/main-banners/banner-wed-mob.png) no-repeat;
            background-size: cover;
            /* margin-top: 600px; */
            margin-left: -1px;
            justify-content: flex-end;
        }
        .slide-2__wrapper{
            margin-bottom: 35px;
            box-shadow: none;
            background: none;
        }
        .slide-2__text_desc{
            display: none;
        }
        .slide-2__text{
            font-size: 12px;
        }
        .slide-2__discount{
            margin-top: 0;
        }
        /* Врем баннер */
        .slide__wrapper{
            display: block;
        }
        .main-banner__text{
            display: flex;
            justify-content: space-between;
        }
        .slide-text__left{
            margin-left: 50px;
            margin-top: 30px;
            max-width: 95px;
            display: flex;
            flex-direction: column;
            color: #AC3E56;
        }
        .slide-text-left__discount{
            text-transform: uppercase;
            font-size: 21px;
            margin-bottom: 5px;
        }
        .slide-text-left__value{
            text-transform: uppercase;
            font-size: 51px;
            font-weight: 600;
            margin-bottom: 5px;
            font-variant-numeric: lining-nums proportional-nums;
        }
        .slide-text-left__text{
            text-align: center;
        }

        .slide-text__right{
            margin-top: 100px;
            display: flex;
            flex-direction: column;
            margin-right: 35px;
            color: #AC3E56;
        }
        .slide-text-right__heading{
            font-size: 19px;
        }
        .slide-text-right__promo{
            font-size: 36px;
            font-weight: 600;
        }
        .slider__slide-mother{
            position: relative;
            background-size: 100% 100%;
            min-height: 666px;
            padding-top: 90px;
            background: url(../images/main-banners/mother-ban-mob.png) no-repeat;
            background-size: cover;
        }
        .slider__slide-mother::before{
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 90px;
            width: 100%;
            background-color: #fff;
        }
        .mother__inner{
            padding: 40px 20px;
            margin-bottom: 0;
        }
        .mother__heading{
            font-size: 66px;
            font-weight: 700;
            margin-bottom: 0;
        }
        .mother__text{
           font-size: 24px;
           margin-bottom: 25px;
        }
        .mother__btn{
            font-size: 18px;
            padding: 10px 20px;
        }
        @media (max-width: 375px) {
            .slider__slide-september{
                background-size: 100% 100%;
            }
        }
    }
</style>
