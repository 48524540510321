<template>
    <div class="search">
        <form class="search__form" @submit.prevent="search">
            <input 
                placeholder="Что Вас интересует?" 
                class="search__input"
                v-model="searchValue">
            <button class="search__btn" @click="search"></button>
        </form>
    </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    data() {
        return {
            searchValue: ''
        }
    },
    methods: {
        ...mapActions([
            'GET_PRODUCTS',
            'RESET_PAGE_NUMBER',
            'GET_SEARCH_VALUE',
            'RESET_CATEGORY',
            'GET_CATEGORY_VALUE'
        ]),
        search(){
            this.GET_SEARCH_VALUE(this.searchValue)
            this.RESET_PAGE_NUMBER()
            this.GET_CATEGORY_VALUE('')
            this.RESET_CATEGORY()
            this.GET_PRODUCTS()
            if(this.$route.name != 'catalog'){
                this.$router.push('/catalog')
            }
        }
    },
}
</script>

<style scoped>
    .search__form{
        position: relative;
        display: flex;
        align-items: center;
    }
    .search__input{
        width: 90%;
        margin-right: 15px;
        padding: 10px;
        font-size: 16px;
        border: none;
        border-bottom: 1px solid #000;
        outline: none;
    }
    .search__input::placeholder{
        color: #A5A5A5;
    }
    .search__btn{
        height: 60px;
        width: 60px;
        background: url(../images/icons/search-ico.svg) no-repeat;
        border-radius: 50%;
        background-position: center;
        background-size: 50%;
        transition: all linear .2s;
        
    }
    .search__btn:hover{
        background-color: #ECDEF6;
    }
    @media (max-width: 768px) {
        .search__input{
            font-size: 14px;
            margin-right: 0;
            width: 100%;
        }
        .search__btn{
            width: 30px;    
            height: 30px;
            position: absolute;
            right: 15px;
            top: 0;
            background-size: auto;
        }
    }
</style> 