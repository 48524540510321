import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '@/pages/Main-page'

const routes = [
    {
        component: MainPage,
        name: 'main',
        path: '/'
    },
    { 
      component:()=> import('@/pages/Delivery-page'),
      name: 'delivery',
      path: '/delivery'
    },
    {
      component:()=> import('@/pages/About-page'),
      name: 'about',
      path: '/about'
    },
    {
      component:()=> import('@/pages/Contacts-page'),
      name: 'contacts',
      path: '/contacts'
    },
    {
      component:()=> import('@/pages/Catalog-page'),
      name: 'catalog',
      path: '/catalog'
    },
    {
      component:()=> import('@/admin/Admin-main-page'),
      name: 'admin',
      path: '/admin'
    },
    {
      component:()=> import('@/pages/Cart-page'),
      name: 'Cart',
      path: '/cart'
    },
    {
      component:()=> import('@/user/pages/Account-page'),
      name: 'account',
      path: '/account'
    },
    {
      path: '/admproduct',
      name: 'admproduct',
      component:()=> import('@/admin/Product-page') 
    },
    {
      path: '/clientprod',
      name: 'clientprod',
      component:()=> import('@/pages/Client-product') 
    },
    // {
    //   path: '/orders',
    //   name: 'Orders-page',
    //   component: ()=> import('@/admin/Orders-page')
    // },
    {
      path: '/chart',
      name: 'chart',
      component: ()=> import('@/admin/Pages/Chart-page')
    },
    {
      path: '/mostpopular',
      name: 'mostpopular',
      component: ()=> import('@/admin/Pages/Pop-products-page')
    },
    {
      path: '/mostorder',
      name: 'mostorder',
      component: ()=> import('@/admin/Pages/Most-order-page')
    },
    // {
    //   path: '/admin-slider',
    //   name: 'admin-slider',
    //   component: ()=> import('@/admin/Admin-slider')
    // },
    {
      path: '/success',
      name: 'success',
      component: ()=> import('@/pages/Success-page')
    },
    // {
    //   path: '/admin-pop',
    //   name: 'adminpop',
    //   component: ()=> import('@/admin/Admin-popular')
    // },
    {
      path: '/confidential',
      name: 'confidential',
      component: ()=> import('@/pages/Confidential-page')
    },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: ()=> import('@/pages/Subscribe-page')
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior() {
  //   document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  // }
  // scrollBehavior: function(to, from, savedPosition) {
  //   if (to.hash) {
  //       return {el: to.hash, behavior: 'smooth'}
  //   } else {
  //       return { x: 0, y: 0 }
  //   }
  // },
})

export default router
