<template>
    <div class="product">
        <div class="product__inner">
            <div class="product-card product__item">
                <button class="product__close" @click="close"></button>
                <div class="product-card__photos">
                    <div class="product-card__main-img">
                        <img class="product-card__img" :src="'https://dff24.ru:8443/' + MAIN_PHOTO">
                        <div 
                            v-if="SELECTED_PRODUCT.url1"
                            class="product-card__sub-photos">

                            <img  
                            v-if="SELECTED_PRODUCT.url"
                            class="product-card__additional" 
                            :src="'https://dff24.ru:8443/' + SELECTED_PRODUCT.url"
                            @click="imgChangeSlide(SELECTED_PRODUCT.url)"
                            :class="{selected : SELECTED_PRODUCT.url == MAIN_PHOTO}">
                            <img 
                            v-if="SELECTED_PRODUCT.url1"
                            class="product-card__additional" 
                            :src="'https://dff24.ru:8443/' + SELECTED_PRODUCT.url1"
                            @click="imgChangeSlide(SELECTED_PRODUCT.url1)"
                            :class="{selected : SELECTED_PRODUCT.url1 == MAIN_PHOTO}">
                            <img 
                            v-if="SELECTED_PRODUCT.url2"
                            class="product-card__additional" 
                            :src="'https://dff24.ru:8443/' + SELECTED_PRODUCT.url2"
                            @click="imgChangeSlide(SELECTED_PRODUCT.url2)"
                            :class="{selected : SELECTED_PRODUCT.url2 == MAIN_PHOTO}">
                            <img 
                            v-if="SELECTED_PRODUCT.url3"
                            class="product-card__additional" 
                            :src="'https://dff24.ru:8443/' + SELECTED_PRODUCT.url3"
                            @click="imgChangeSlide(SELECTED_PRODUCT.url3)"
                            :class="{selected : SELECTED_PRODUCT.url3 == MAIN_PHOTO}">
                        </div>
                    </div>
                </div>

                <div class="product-card__info">
                    <h1 class="product-card__name">{{SELECTED_PRODUCT.name}}</h1>
                    <p class="product-card__description product-card__description-mob"><span class="product-card__description-marked">Состав: </span> {{SELECTED_PRODUCT.structure}}</p>

                    

                    <div class="product-card_mobile">
                        <div class="product-card__buttons">
                            <div class="controls product-card__controls">
                                <span class="product-card__price">{{SELECTED_PRODUCT.price}} р</span>
                                <div class="controls__quantity">
                                    <button class="controls__decrease controls__btn" @click="quantityDecrease">
                                    </button>
                                             
                                    <output class="controls__output">{{SELECTED_PRODUCT.quantity}}</output>
                    
                                    <button class="controls__increase controls__btn" @click="quantityIncrease">
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div 
                        class="product-card__related related"
                        v-if="SELECTED_PRODUCT.category == 'Цветы поштучно'">
                        <!-- <div class="controls-radio related__controls">
                            <input 
                                type="radio" 
                                id="lent"
                                name="related" 
                                class="controls-radio__btn"
                                @change="selectRelated(180, 'добавить ленту - 180 р')"
                                >
                            <label
                                class="controls-radio__label"
                                for="lent">добавить ленту - 180 р</label>
                        </div>

                        <div class="controls-radio related__controls">
                            <input 
                                type="radio" 
                                id="transparent"
                                name="related" 
                                class="controls-radio__btn"
                                @change="selectRelated(140, 'упаковка прозрачная - 140 р')"
                                >
                            <label
                                class="controls-radio__label"
                                for="transparent">упаковка прозрачная - 140 р</label>
                        </div>

                        <div class="controls-radio related__controls">
                            <input 
                                type="radio" 
                                id="craft"
                                name="related" 
                                class="controls-radio__btn"
                                @change="selectRelated(160, 'упаковка крафт - 160 р')"
                                >
                            <label 
                                class="controls-radio__label"
                                for="craft">упаковка крафт - 160 р</label>
                        </div>

                        <div class="controls-radio related__controls">
                            <input 
                                type="radio" 
                                id="lux"
                                name="related" 
                                class="controls-radio__btn"
                                @change="selectRelated(300, 'упаковка люкс - 300 р')"
                                >
                            <label 
                                class="controls-radio__label"
                                for="lux">упаковка люкс - 300 р</label>
                        </div>

                        <div class="controls-radio related__controls">
                            <input 
                                type="radio" 
                                id="foamiran"
                                name="related" 
                                class="controls-radio__btn"
                                @change="selectRelated(340, 'упаковка фоамиран - 340 р')"
                                >
                            <label  
                                class="controls-radio__label"
                                for="foamiran">упаковка фоамиран - 340 р</label>
                        </div> -->
                        <div class="checkbox checkbox__wrapper" :class="{disabled: packPrice}">
                            <span 
                                class="checkbox__ico"
                                :class="{checkbox_select: lentSelected}"
                                @click="selectLent(180, 'добавить ленту - 180 р')"></span>
                            <input 
                                id="checkbox" 
                                type="checkbox" 
                                class="checkbox__input">

                            <label 
                                for="checkbox" 
                                class="checkbox__label"
                                @click="selectLent(180, 'добавить ленту - 180 р')">добавить ленту - 180 р</label>
                        </div>

                        <div class="checkbox checkbox__wrapper">
                            <span 
                                class="checkbox__ico"
                                :class="{checkbox_select: packPrice == 140}"
                                @click="selectPack(140 ,'упаковка прозрачная - 140 р')"></span>
                            <input 
                                id="checkbox" 
                                type="checkbox" 
                                class="checkbox__input">

                            <label 
                                for="checkbox" 
                                class="checkbox__label"
                                @click="selectPack(140 ,'упаковка прозрачная - 140 р')">упаковка прозрачная - 140 р</label>
                        </div>

                        <div class="checkbox checkbox__wrapper">
                            <span 
                                class="checkbox__ico"
                                :class="{checkbox_select: packPrice == 160}"
                                @click="selectPack(160 ,'упаковка крафт - 160 р')"></span>
                            <input 
                                id="checkbox" 
                                type="checkbox" 
                                class="checkbox__input">

                            <label 
                                for="checkbox" 
                                class="checkbox__label"
                                @click="selectPack(160 ,'упаковка крафт - 160 р')">упаковка крафт - 160 р</label>
                        </div>

                        <div class="checkbox checkbox__wrapper">
                            <span 
                                class="checkbox__ico"
                                :class="{checkbox_select: packPrice == 300}"
                                @click="selectPack(300 ,'упаковка люкс - 300 р')"></span>
                            <input 
                                id="checkbox" 
                                type="checkbox" 
                                class="checkbox__input">

                            <label 
                                for="checkbox" 
                                class="checkbox__label"
                                @click="selectPack(300 ,'упаковка люкс - 300 р')">упаковка люкс - 300 р</label>
                        </div>

                        <div class="checkbox checkbox__wrapper">
                            <span 
                                class="checkbox__ico"
                                :class="{checkbox_select: packPrice == 340}"
                                @click="selectPack(340 ,'упаковка фоамиран - 340 р')"></span>
                            <input 
                                id="checkbox" 
                                type="checkbox" 
                                class="checkbox__input">

                            <label 
                                for="checkbox" 
                                class="checkbox__label"
                                @click="selectPack(340 ,'упаковка фоамиран - 340 р')">упаковка фоамиран - 340 р</label>
                        </div>
                    </div>

                        <div class="order-btns product-card__cart">
                            <button class="order-btns__cart" @click="addToCart">{{textBtnAddToCart}}</button>

                            <button 
                                class="order-btns__one-click"
                                @click="oneClickOrder">Купить в один клик</button>

                        </div>
                        

                        <div class="product-card__text">
                            <p class="product-card__description "><span class="product-card__description-marked">Состав: </span> {{SELECTED_PRODUCT.structure}}</p>

                            <p class="product-card__description-info">В случае отсутствия каких-либо цветов (определенного вида или оттенка) либо элементов декора магазин оставляет за собой право осуществить эквивалентную по стоимости замену с сохранением общей концепции букета.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    data() {
        return {
           mainPhoto: '',
           isAddedInCart: false,
           lentSelected: false,
           packType: '',
           lentName: 'добавить ленту - 180 р',
           lentPrice: 0,
           packPrice: 0
        }
    },
    computed: {
        ...mapGetters([
            'SELECTED_PRODUCT',
            'MAIN_PHOTO'
        ]),
        textBtnAddToCart(){
            return this.isAddedInCart ? 'Товар в корзине' :'В корзину'
        },
    },
    methods: {
        ...mapActions([
            'ADD_TO_CART',
            'SELECT_MAIN_PHOTO'
        ]),
        close(){
            this.$emit('closePopup', false)
        },
        imgChangeSlide(url){
            this.SELECT_MAIN_PHOTO(url)
        },
        quantityDecrease(){
            if (this.SELECTED_PRODUCT.quantity > 0) {
                this.SELECTED_PRODUCT.quantity--
            }
        },
        quantityIncrease(){
            this.SELECTED_PRODUCT.quantity++
        },
        addToCart(){
            this.isAddedInCart = true
            this.ADD_TO_CART(this.SELECTED_PRODUCT)
        },
        oneClickOrder(){
            this.$emit('oneClickOrder', this.SELECTED_PRODUCT)
        },
        // selectRelated(price, name){
        //     this.SELECTED_PRODUCT.relatedPrice = price
        //     this.SELECTED_PRODUCT.related = name
        // }
        selectLent(price, name){
            this.lentPrice = price
            this.lentName = name
            if(this.packPrice){
                this.lentSelected = true
            }else{
                this.lentSelected = !this.lentSelected
            }
            if (!this.lentSelected) {
                this.lentName = ''
                this.lentPrice = 0
            }
            this.SELECTED_PRODUCT.relatedPrice = this.lentPrice
            this.SELECTED_PRODUCT.related = this.lentName 

            console.log(this.SELECTED_PRODUCT.relatedPrice);
            console.log(this.SELECTED_PRODUCT.related);
        },
        selectPack(price, name){
            this.lentSelected = true
            this.lentPrice = 180
            this.packType = name
            if (this.packPrice == price) {
                this.packPrice = 0
                this.packType = ''
                this.SELECTED_PRODUCT.relatedPrice = this.packPrice + this.lentPrice
                this.SELECTED_PRODUCT.related = "добавить ленту 180 р"
                console.log(this.SELECTED_PRODUCT.relatedPrice);
                console.log(this.SELECTED_PRODUCT.related);
                return 
            }
            this.packPrice = price
            this.SELECTED_PRODUCT.relatedPrice = this.packPrice + this.lentPrice
            this.SELECTED_PRODUCT.related = this.packType + " , добавить ленту 180 р"
            console.log(this.SELECTED_PRODUCT.relatedPrice);
            console.log(this.SELECTED_PRODUCT.related);
        }
    }
}
</script>

<style scoped>
    .product__inner{
        position: fixed;
        display: flex;
        z-index: 1000;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 60px;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, .5);
    }
    .product__item{
        display: flex;
        position: relative;
        max-width: 1240px;
        width: 100%;
        padding: 40px 60px 60px 100px;
        background-color: #fff;
        border-radius: 30px 0 0 30px;
        overflow-y: scroll;
    }
    .product__item::-webkit-scrollbar {
        width: 5px;               
    }
    .product__item::-webkit-scrollbar-track {
        background: #fff;        
    }
    .product__item::-webkit-scrollbar-thumb {
        background-color: #707070;    
        border-radius: 20px;       
        border: 3px solid #707070;  
    }
    .product-card__photos{
        margin-right: 100px;
    }
    .product-card__main-img{
        display: flex;
        flex-direction: row-reverse;
    }
    .product-card__img{
        width: 100%;
        max-width: 425px;
        aspect-ratio: 1 / 1;
    }
    .product-card__sub-photos{
        margin-right: 30px;
        display: flex;
        flex-direction: column;
    }
    .product-card__additional{
        max-width: 75px;
        aspect-ratio: 1 / 1;
        margin-bottom: 15px;
        transition: all linear .2s;
    }
    .product-card__additional:last-child{
        margin-bottom: 0;
    }
    .product-card__additional:hover{
        cursor: pointer;
        opacity: .5;
    }
    .product-card__info{
        max-width: 425px;
    }
    .product-card__name{
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 25px;
    }
    .product-card__price{
        font-size: 30px;
        margin-right: 45px;
    }
    .product-card__quantity{
        display: block;
        margin-bottom: 20px;
    }
    .product-card__controls{
        display: flex;
        align-items: center;
        margin-bottom: 40px;
    }
    .controls__quantity{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #000;
        max-width: 150px;
        height: 40px;
        width: 100%;
        border-radius: 6px;
    }
    .controls__btn{
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 44px;
        transition: all linear .2s;
        cursor: pointer;
        border-radius: 5px;
    }
    .controls__decrease:hover{
        background-color: #F6F1FA;
    }
    .controls__decrease::before{
        position: absolute;
        content: "";
        height: 1px;
        width: 17px;
        top: 50%;
        left: 50%;
        margin-left: -9px;
        background-color: #000;
    }
    .controls__increase:hover{
        background-color: #F6F1FA;
    }
    .controls__increase::before{
        position: absolute;
        content: "";
        height: 1px;
        width: 17px;
        top: 50%;
        left: 50%;
        margin-left: -9px;
        background-color: #000;
    }
    .controls__increase::after{
        position: absolute;
        content: "";
        height: 1px;
        width: 17px;
        top: 50%;
        left: 50%;
        margin-left: -9px;
        transform: rotate(90deg);
        background-color: #000;
    }
    .controls__output{
        display: inline-block;
        font-size: 20px;
        margin: 0 10px;
    }
    .product-card__cart{
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
    }
    .order-btns__cart{
        padding: 15px 25px;
        color: #fff;
        background-color: #C8A2E5;
        font-size: 16px;
        margin-right: 25px;
        border-radius: 6px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: 'Raleway', sans-serif;
        cursor: pointer;
        transition: all linear .2s;
    }
    .order-btns__cart:hover{
        background-color: #BD91DE   
    }
    .order-btns__one-click{
        position: relative;
        color: #fff;
        background-color: #A875D2;
        border-radius: 6px;
        padding: 15px 15px 15px 60px;
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
        font-family: 'Raleway', sans-serif;
        cursor: pointer;
        transition: all linear .2s;
    }
    .order-btns__one-click:hover{
        background-color: #9650cf;
    }
    .order-btns__one-click::before{
        position: absolute;
        content: "";
        height: 35px;
        width: 35px;
        top: 8px;
        left: 15px;
        background: url(../images/icons/one-click-ico.png) no-repeat;
    }
    /* radio */
    .product-card__related{
        margin-bottom: 40px;
    }
    .checkbox__wrapper{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .checkbox__wrapper:last-child{
        margin-bottom: 0;
    }
    .checkbox__ico{
        position: relative;
        display: inline-block;
        vertical-align: baseline;
        height: 20px;
        width: 20px;
        margin-right: 20px;
        margin-bottom: -2px;
        border: 1px solid #000;
        border-radius: 4px;
        cursor: pointer;
    }
    .checkbox__input{
        display: none;
    }
    .checkbox_select{
        background-color: #000;
        transition: all linear .1s;
    }
    .checkbox_select::before{
        position: absolute;
        content: "";
        height: 12px;
        width: 2px;
        top: 3px;
        right: 6px;
        transform: rotate(35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox_select::after{
        position: absolute;
        content: "";
        height: 7px;
        width: 2px;
        bottom: 4px;
        left: 5px;
        transform: rotate(-35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox__label{
        cursor: pointer;
    }
    /*      */
    .product-card__description{
        margin-bottom: 30px;
    }
    .product-card__description:last-child{
        margin-bottom: 0;
    }
    .product-card__description-marked{
        font-weight: 700;
    }
    .product-card__description-info{
        position: relative;
        font-size: 14px;
        padding: 25px 20px 25px 65px;
        border-radius: 15px;
        line-height: 130%;
        background-color: #F6F1FA;
    }
    .product-card__description-info::before{
        position: absolute;
        content: "";
        height: 25px;
        width: 25px;
        top: 20px;
        left: 20px;
        background: url(../images/icons/info-ico.png) no-repeat;
    }
    .product__close{
        position: absolute;
        top: 45px;
        right: 60px;
        height: 40px;
        width: 40px;
    }
    .product__close::before{
        position: absolute;
        content: "";
        height: 2px;
        width: 40px;
        background-color: #000;
        top: 20px;
        right: 0;
        transform: rotate(45deg);
    }
    .product__close::after{
        position: absolute;
        content: "";
        height: 2px;
        width: 40px;
        background-color: #000;
        top: 20px;
        right: 0;
        transform: rotate(-45deg);
    }
    .selected{
        opacity: .5;
        pointer-events: none;
    }
    .product-card__description-mob{
        display: none;
    }
    .disabled{
        pointer-events: none;
    }
    @media (max-width: 1090px) {
        .product-card__photos{
            margin-right: 25px;
        }
        .product-card__additional{
            max-width: 75px;
        }
    }
    @media (max-width: 992px){
        .product-card__main-img{
            min-width: 290px;
        }
        .product-card__name{
            font-size: 26px;
        }
        .product-card__controls{
            align-items: center;
        }
        .product-card__cart{
            flex-direction: column;
            margin-bottom: 30px;
        }
        .order-btns__cart{
            margin-right: 0;
            margin-bottom: 15px;
        }
    }
    @media (max-width: 768px){
        .product__item{
            flex-direction: column;
            overflow-y: scroll;
        }
        .product-card__photos{
            margin-top: 70px;
            margin-right: 0;
        }
        .product-card__main-img{
            max-width: none;
        }
        .product-card__controls{
            align-items: inherit;
        }
    }
    @media (max-width: 600px){
        .product-card__description{
            display: none;
        }
        .product-card__description-mob{
            display: block;
        }
        .product__inner{
            padding: 0;
        }
        .product__item{
            border-radius: 36px;
        }
        /* .product-card__main-img{
            flex-direction: column;
        } */
        .product-card__img{
            width: 100%;
            max-width: none;
        }
        .product-card__info{
            padding-top: 25px;
            max-width: none;
        }
        .product-card__name{ 
            font-size: 16px;
            margin-bottom: 25px;
        }
        .product-card__price{
            font-size: 24px;
            margin: 0;
        }
        .product-card__additional{
            margin-right: 10px;
        }
        .product-card_mobile{
            display: flex;
            flex-direction: column-reverse;
        }
        .product-card__buttons{
            order: 3;
        }
        .product-card__cart{
            order: 2;
        }
        .product-card__related{
            order: 1;
        }
        .order-btns__cart{
            margin-right: 0;
            margin-bottom: 15px;
        }
        .product-card__controls{
            flex-direction: row-reverse;
            justify-content: flex-end;
            margin-bottom: 25px;
        }
        .product__item{
            padding: 15px;
            padding-bottom: 45px;
        }
        .controls__quantity{
            margin-right: 50px;
        }
        .product__close{
            right: 15px;
            top: 25px;
        }
    }
</style>