<template>
    <div class="range">
        <div class="range__inner">
            <div class="range__wrapper">
                <div class="range__inputs">
                    <input 
                    type="number" 
                    class="range__minValue range__input" 
                    v-model.number="minPrice" 
                    @change="setRange(minPrice, maxPrice)">

                    <span class="range__dash"></span>
                    <div class="range__group">
                        <input 
                            type="number" 
                            class="range__maxValue range__input" 
                            v-model.number="maxPrice" 
                            @change="setRange(minPrice, maxPrice)">

                        <span class="range__currency">руб</span>
                    </div>
                </div>
                <div class="range__controls">
                    <input 
                        type="range" 
                        min="0" 
                        max="30000" 
                        step="10" 
                        class="range__min"
                        v-model.number="minPrice"
                        @change="setRange(minPrice, maxPrice)"
                        >   
                    <input 
                        type="range" 
                        min="0" 
                        max="30000" 
                        step="10" 
                        class="range__max"
                        v-model.number="maxPrice"
                        @change="setRange(minPrice, maxPrice)"
                        >
                </div>
            </div>
            <button 
                class="range__submit"
                @click="sortByPrice">Применить</button>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
    data(){
        return{
            minPrice: 0,
            maxPrice: 30000
        }
    },
    methods: {
        ...mapActions([
            'GET_MIN_VALUE', 
            'GET_MAX_VALUE',
            'GET_PRODUCTS'
        ]),
        setRange(minVal, maxVal){
            if(this.minPrice > this.maxPrice){
                let tmp = this.maxPrice;
                this.maxPrice = this.minPrice;
                this.minPrice = tmp;
                let tmp2 = maxVal;
                maxVal = minVal
                minVal = tmp2
            }
            this.GET_MIN_VALUE(minVal);
            this.GET_MAX_VALUE(maxVal);
        },
        sortByPrice(){
            this.GET_PRODUCTS();
        }
    }
}
</script>

<style scoped>
    .range__inner{
        display: flex;
    }
    .range__inputs{
        display: flex;
        align-items: center;
        margin-bottom: 45px;
    }
    .range__input{
        padding: 14px 15px;
        border: 1px solid #000;
        border-radius: 6px;
        font-size: 16px;
        font-family: 'Raleway', sans-serif;
        max-width: 100px;
        text-align: center;
    }
    .range__dash{
        margin-right: 20px;
        margin-left: 20px;
        height: 1px;
        width: 16px;
        background-color: #000;
    } 
    .range__currency{
        font-size: 16px;
        margin-left: 15px;
        margin-right: 40px;
    }
    .range__submit{
        padding: 13px 35px;
        border-radius: 6px;
        background-color: #ECDEF6;
        color: #7E46AD;
        font-size: 16px;
        align-self: flex-start;
        transition: all linear .2s;
    }
    .range__submit:hover{
        background-color: #E7D0F8;
    }
    .range__submit:active{
        background-color: #DEBBF6;
    }
    .range__controls{
        max-width: 260px;
        text-align: center;
        position: relative;
    }
    .range__controls::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: #ECDEF6;
        left: 0;
        top: -7px;
        border-radius: 5px;
    }
    .range__controls svg, .range__controls input[type=range]{
        position: absolute;
        left: 0;
        bottom: 0;
        -webkit-appearance: none;
    }
    input[type=range]::-webkit-slider-thumb{
        height: 20px;
        width: 20px;
        border-radius: 50%;
        z-index: 2;
        position: relative;
        top: 5px;
        margin-top: -7px;
        -webkit-appearance: none;
        background-color: #000;
    }
    input[type=range]{
        width: 100%;
    }
    @media (max-width: 992px) {
        .range__wrapper{
            margin-bottom: 30px;
        }
        .range__inner{
            flex-direction: column;
        }
        .range__currency{
            margin-right: 0;
        }
        .range__submit{
            width: 100%;
        }
    }
    @media (max-width: 768px){
        .range__controls{
            max-width: none;
        }
        .range__inputs{
            justify-content: space-between;
        }
    }  
</style>