<template>
    <div class="catalog">
        <div class="catalog-notification">
            <CartNotification :messages="messages"/>
        </div>
        <div :class="{show: !isProductOpened}">
            <ProductPopup
                @oneClickOrder="oneClickOrder"
                @closePopup="closePopup"/>
        </div>

        <div :class="{show: !isOneClickOrder}">
            <OneClickPopup
                @closeOneClick="closeOneClick"/>
        </div>

        <div class="catalog__sort" v-if="this.$route.name == 'main'">
            <SortBlock/>
        </div>
    
        <div class="catalog__inner">
            <CatalogItem
                v-for="flower in PRODUCTS.rows" 
                :key="flower.id"
                :product_data="flower"
                @addToCart="addToCart"
                @showProduct="showProduct"
                @openSeason="openSeason"/>
        </div>
        <SeasonPopup
            v-if="seasonOpened"
            @closeSeason="closeSeason"
            @addSeasonToCart="addSeasonToCart"/>
        <div ref="observer" class="observer"></div>
        <Pagination/>
        <ConsultationPopup
            @closeConsultation="closeConsultation"
            v-if="isConsultation && this.$route.name=='main'"/>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import CatalogItem from '@/components/Catalog-item'
import ProductPopup from '@/components/Product-popup'
import CartNotification from '@/components/Cart-notification';
import SortBlock from '@/components/Sort-block'
import OneClickPopup from '@/components/popups/One-click-popup'
import Pagination from '@/components/Pagination'
import SeasonPopup from '@/components/popups/Season-popup'
import ConsultationPopup from '@/components/popups/Consultation-popup'

export default {
    data() {
        return {
            isConsultation: false,
            isOneClickOrder: false,
            isProductOpened: false,
            oneClickProduct: '',
            seasonOpened: false,
            messages: [],
            selectedPage: '',
            isMob: false,
            product: ''
        }
    },
    methods: {
        ...mapActions([
            'GET_PRODUCTS',
            'GET_PAGE_NUMBER',
            'INCREASE_PAGE_NUMBER',
            'DECREASE_PAGE_NUMBER',
            'GET_SUBCATEGORIES',
            'GET_MOST_ORDERED',
            'GET_MOST_POPULAR',
            'RESET_PAGE_NUMBER',
            'ADD_TO_CART',
            'TRIGGER_IS_CONSULTATION',
            'SELECT_CATEGORY'
        ]),
        oneClickOrder(data){
            this.isOneClickOrder = true
            this.oneClickProduct = data
            document.body.style.overflowY = 'hidden'
        }, 
        closeSeason(){
            this.seasonOpened = false
        },
        openSeason(data){
            this.product = data
            this.seasonOpened = true
        },
        addSeasonToCart(){
            this.ADD_TO_CART(this.product)
            this.addToCart()
        },
        closeConsultation(){
            this.isConsultation = false
        },
        // prevPage(){
        //     this.DECREASE_PAGE_NUMBER()
        //     this.GET_PRODUCTS()
        //     window.scrollTo(0, 0)
        // },
        // nextPage(){
        //     this.INCREASE_PAGE_NUMBER()
        //     this.GET_PRODUCTS()
        //     window.scrollTo(0, 0)
        // },
        
        showProduct(bool){
            this.isProductOpened = bool
            document.body.style.overflowY = 'hidden'
        },
        closePopup(bool){
            this.isProductOpened = bool
            document.body.style.overflowY = 'scroll'
        },
        closeOneClick(bool){
            this.isOneClickOrder = bool
            document.body.style.overflowY = 'scroll'
        },
        addToCart(){
            let timeStamp = Date.now().toLocaleString()
            this.messages.unshift(
                {name: "Товар добавлен в корзину", id: timeStamp}
            )
        },
        pageClick(page){
            this.GET_PAGE_NUMBER(page)
            this.GET_PRODUCTS();
        },
        isPagesOverflow(){
            return this.TOTAL_PAGES > 10 ? true : false
        },
        isPagesMore(){
            return this.TOTAL_PAGES > 2 ? true : false
        }
    }, 
    components: {
        CatalogItem,
        ProductPopup,
        CartNotification,
        SortBlock,
        OneClickPopup,
        Pagination,
        SeasonPopup,
        ConsultationPopup
    },
    computed: {
        ...mapGetters([
            'PRODUCTS',
            'TOTAL_PAGES',
            'SELECTED_PAGE',
            'IS_CONSULTATION_CALLED'
        ])
    },
    mounted() {
        if(this.$route.name == 'mostpopular'){
            this.RESET_PAGE_NUMBER()
            this.GET_MOST_POPULAR()
        }else if(this.$route.name == 'mostorder'){
            this.RESET_PAGE_NUMBER()
            this.GET_MOST_ORDERED()
        }else{
            if (!this.$route.query.category) {
                this.$router.push({query: {'category': 'Все', 'page': 1}})
                this.RESET_PAGE_NUMBER()
                this.GET_PAGE_NUMBER(1)
                this.SELECT_CATEGORY('Все')
                this.GET_PRODUCTS()
            }else{
                this.RESET_PAGE_NUMBER()
                this.GET_PAGE_NUMBER(this.$route.query.page)
                this.SELECT_CATEGORY(this.$route.query.category)
                this.GET_PRODUCTS()
            }
        }
        this.GET_SUBCATEGORIES()
        if(window.screen.width <= 600){
            this.isMob = true
        }
        let options = {
            rootMargin: '0px',
            threshold: 1.0
        }
        let callback = (entries, observer) =>{
            if(entries[0].isIntersecting && !this.IS_CONSULTATION_CALLED){
                this.isConsultation = true
                this.TRIGGER_IS_CONSULTATION()
            }
        };
        let observer = new IntersectionObserver(callback, options);
        observer.observe(this.$refs.observer);
    },
}
</script>

<style scoped>
    
    .page-link{
        text-decoration: none;
        display: block;
        height: 36px;
        width: 36px;
        line-height: 36px;
        text-align: center;
        font-size: 20px;
        margin-right: 50px;
        border-radius: 50%;
        transition: all linear .2s;
        color: #000;
    }
    .catalog__sort{
        margin-bottom: 60px;
    }
    .catalog__inner{
        min-height: 100vh;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;
        row-gap: 40px;
        margin-bottom: 100px;
    }
    .catalog__pagination{
        display: flex;
        justify-content: center;
    }
    .pagination__inner{
        display: flex;
        align-items: center;
    }
    .pagination__btn{
        height: 12px;
        width: 12px;
        border-right: 2px solid #000;
        border-top: 2px solid #000;
    }
    .pagination__btn:hover{
        border-color: #BD91DE;
    }
    .pagination__prev{
        transform: rotate(-135deg);
        margin-right: 60px;
    }
    .prevDisabled{
        opacity: .3;
        pointer-events: none;
    }
    .nextDisabled{
        opacity: .3;
        pointer-events: none;
    }
    .pagination__next{
        transform: rotate(45deg);
        margin-left: 60px;
    }
    .pagination__numbers{
        display: flex;
    }
    .test{
        font-size: 24px;
        margin-right: 24px;
        position: relative;
        display: block;
        color: #fff;
    }
    
    .pageOffset{
        margin-right: 5px;
    }
    .pagination__page:last-child{
        margin-right: 0;
    }
    .pagination__page:hover{
        background-color: #ECDEF6;
    }
    .page__selected{
        background-color: #ECDEF6;
        pointer-events: none;
    }
    .show{
        display: none;
    }
    @media (max-width: 600px) {
        .catalog__sort{
            margin-bottom: 40px;
        }
        .catalog__inner{
            grid-template-columns: repeat(1, 1fr);
            row-gap: 40px;
            margin-bottom: 60px;
        }
        .pagination__page{
            margin-right: 15px;
        }
        .pagination__page:hover{
            background-color: transparent;
        }
    }
</style>