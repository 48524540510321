<template>
    <div class="page-loading">
        <div class="page-loading__inner">
            <img 
                class="page-loading__logo" 
                src="../images/logo.svg">
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .page-loading__inner{
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: linear-gradient(#eadff3, #fff);
        z-index: 10000;
    }
    .page-loading__logo{
        width: 100%;
        max-width: 400px;
        animation: size 3s infinite;
    }
    @keyframes size {
        0% {
            transform: scale(100%);
        }
        50% {
            transform: scale(120%);
        }
        100% {
            transform: scale(100%);
        }
    }
    @media (max-width: 600px) {
        .page-loading__logo{
            max-width: 150px;
        }
    }
</style>