<template>
    <Paginate
        :page-count="TOTAL_PAGES"
        :click-handler="changePage"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'catalog__pagination'"
        :page-class="'page-item'"
        :page-link-class="'page-link'"
        :active-class="'active-item'"
        :disabled-class="'disabled-item'"
        :break-view-link-class="'test'"
        />
</template>

<script>
import Paginate from 'vuejs-paginate-next';
import {mapActions, mapGetters} from 'vuex';

export default {
    components:{
        Paginate
    },
    computed: {
        ...mapGetters([
            'TOTAL_PAGES'
        ])
    },
    methods: {
        ...mapActions([
            'GET_PRODUCTS',
            'GET_PAGE_NUMBER',
            'GET_MOST_ORDERED',
            'GET_MOST_POPULAR',
        ]),
        changePage(pageNum){
            this.$emit('pageChanged')
             if(this.$route.name == 'mostpopular'){
                this.GET_PAGE_NUMBER(pageNum)
                this.GET_MOST_POPULAR()
                window.scrollTo(0, 0)
             }else if(this.$route.name =='mostorder'){
                this.GET_PAGE_NUMBER(pageNum)
                this.GET_MOST_ORDERED()
                window.scrollTo(0, 0)
             }else if(this.$route.name=='main'){
                this.GET_PAGE_NUMBER(pageNum)
                this.GET_PRODUCTS();
                this.$router.push({query: {'category': this.$route.query.category, 'page': pageNum}})
                window.scrollTo(0, 1950)
             }else{
                this.GET_PAGE_NUMBER(pageNum)
                this.GET_PRODUCTS();
                window.scrollTo(0, 600)
             }
            // this.GET_PAGE_NUMBER(pageNum)
            // this.GET_PRODUCTS();
            // window.scrollTo(0, 600)
        }
    },
}
</script>

<style>
    .catalog__pagination{
        display: flex;
        justify-content: center;
    }
    .page-item{
        list-style: none;
        border-radius: 50%;
        margin-right: 25px;
        height: 36px;
        width: 36px;
        line-height: 36px;
        text-align: center;
        font-size: 20px;
        border-radius: 50%;
        transition: all linear .2s;
        color: #000;
        cursor: pointer;
    }
    /* .page-link{
        text-decoration: none;
        display: block;
        height: 36px;
        width: 36px;
        line-height: 36px;
        text-align: center;
        font-size: 20px;
        border-radius: 50%;
        transition: all linear .2s;
        color: #000;
        cursor: pointer;
    } */
    .page-link{
        display: block;
        width: 100%;
    }
    .page-item:hover{
        background-color: #ECDEF6;
    }
    .active-item{
        background-color: #ECDEF6;
        pointer-events: none;
    }
    .disabled-item{
        opacity: .5;
        pointer-events: none;
    }
    .test{
        /* display: none;
        height: 0;
        width: 0;
        margin-right: 0; */
    }
    @media (max-width: 600px) {
        .page-item{
            margin-right: 10px;
            height: 30px;
            width: 30px;
            line-height: 30px;
        }
    }
</style>