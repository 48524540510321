<template>
    <div class="popup">
        <div class="popup__inner">
            <div class="popup__content">
                <button class="popup__close" @click="close"></button>
                <p class="popup__text">Товар может содержать сезонные цветы. Мы соберем похожий букет или даже лучше! Согласуем с Вами после оформления заказа</p>
                <button
                    type="button"
                    @click="addToCart" 
                    class="popup__btn btn">Добавить в корзину</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        close(){
            this.$emit('closeSeason')
        },
        addToCart(){
            this.$emit('addSeasonToCart')
            this.$emit('closeSeason')
        }
    },
}
</script>

<style scoped>
    .popup__inner{
        position: fixed;
        display: flex;
        z-index: 100;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 60px;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, .5);
    }
    .popup__close{
        position: absolute;
        top: 45px;
        right: 60px;
        height: 20px;
        width: 20px;
    }
    .popup__close::before{
        position: absolute;
        content: "";
        height: 2px;
        width: 25px;
        background-color: #707070;
        top: 12px;
        right: 0;
        transform: rotate(45deg);
    }
    .popup__close::after{
        position: absolute;
        content: "";
        height: 2px;
        width: 25px;
        background-color: #707070;
        top: 12px;
        right: 0;
        transform: rotate(-45deg);
    }
    .popup__content{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        padding: 80px 160px;
        border-radius: 30px;
        max-width: 880px;
    }
    .popup__subheading{
        margin-bottom: 60px;
        font-size: 24px;
    }
    .popup__btn{
        padding: 15px 70px;
    }
    .popup__text{
        max-width: 400px;
        text-align: center;
        margin-bottom: 25px;
    }
    @media (max-width: 600px) {
        .popup__inner{
            padding-left: 10px;
            padding-right: 10px;
        }
        .popup__content{
            padding-right: 40px;
            padding-left: 40px;
        }
        .popup__btn{
            padding-left: 15px;
            padding-right: 15px;
        }
        .popup__close{
            top: 20px;
            right: 20px;
        }
    }
</style>