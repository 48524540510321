<template>
    <div class="banner">
        <div class="banner__wrapper">
            <div class="banner__inner">
                <h1 class="banner__heading heading">Доставка</h1>
                <p class="banner__text">Мы предлагаем вам два способа заказа – звонок по телефону или оформление заявки на сайте. Если вам удобно забрать заказ самостоятельно, то вы всегда можете это сделать в наших магазинах.</p>

                <span class="banner__notify">Уважаемые покупатели! Внимательно читайте условия доставки.</span>
                
                <h1 class="banner__heading heading">Оплата</h1>
                <p class="banner__text banner__text-payment">Оплата цветов производится онлайн.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .banner__wrapper{
        position: relative;
    }
    .banner__wrapper::before{
        position: absolute;
        content: "";
        height: 250px;
        width: 975px;
        background-color: transparent;
        border-radius: 20px 0;
        border-top: 1px solid #000;
        border-left: 1px solid #000;
        top: 0;
        left: 0;
    }
    .banner__wrapper::after{
        position: absolute;
        content: "";
        height: 230px;
        width: 1000px;
        background-color: transparent;
        border-radius: 20px 0;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;
        right: 0;
        bottom: 0;
    }
    .banner__inner{
        position: relative;
        padding: 65px 20px;
        text-align: center;
    }
    .banner__inner::before{
        position: absolute;
        content: "";
        width: 380px;
        height: 450px;
        background: url(../images/banner-deco-1.svg) no-repeat;
        bottom: -176px;
        left: -145px;
    }
    .banner__inner::after{
        position: absolute;
        content: "";
        width: 370px;
        height: 310px;
        background: url(../images/banner-deco-2.svg) no-repeat;
        top: -75px;
        right: -100px;
    }
    .banner__heading{
        margin-bottom: 30px;
    }
    .banner__text{
        font-size: 18px;
        max-width: 725px;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .banner__notify{
        display: inline-block;
        font-weight: 700;
        margin-bottom: 40px;
    }
    .banner__text:last-child{
        margin-bottom: 0;
    }
    @media (max-width: 1450px) {
        .banner__wrapper::after,
        .banner__wrapper::before,
        .banner__inner::after,
        .banner__inner::before{
            display: none;
        }
        .banner__inner{
            border: 1px solid #000;
            border-radius: 20px;
        }
    }
    @media (max-width: 600px){
        .banner__inner{
            border: none;
            padding-left: 30px;
            padding-right: 30px;
        }
        .banner__wrapper::after{
            display: block;
            height: 336px;
            width: 73%;
            background-color: transparent;
            border-radius: 20px 0;
            border-bottom: 1px solid #000;
            border-right: 1px solid #000;
            right: 0;
            bottom: 0;
        }
        .banner__wrapper::before{
            display: block;
            height: 235px;
            width: 68%;
            background-color: transparent;
            border-radius: 20px 0;
            border-top: 1px solid #000;
            border-left: 1px solid #000;
            top: 0;
            left: 0;
        }
        .banner__inner::after{
            display: block;
            width: 207px;
            height: 174px;
            background: url(../images/banner-deco-2.svg) no-repeat;
            background-size: cover;
            top: -45px;
            right: -85px;
        }
        .banner__inner::before{
            display: block;
            width: 213px;
            height: 255px;
            background: url(../images/banner-deco-1.svg) no-repeat;
            background-size: cover;
            bottom: -45px;
            left: -110px;
        }
        .banner__heading{
            margin-bottom: 20px;
        }
        .banner__text{
            font-size: 14px;
            line-height: 140%;
        }
        .banner__notify{
            font-size: 14px;
        }
        .banner__text-payment{
            max-width: 170px;
        }
    }
</style>